<template>
  <div class="settings_manufacturing_boxes_printtemplates">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='templates'" v-on:click="tab='templates'">Templates</b-nav-item>
      <b-nav-item v-if="loadedTemplate" :active="tab=='current'" v-on:click="tab='current'">{{ loadedTemplate.name }}</b-nav-item>
    </b-nav>

    <printtemplates v-if="tab=='templates'" @create="onCreate" @edit="onEdit"></printtemplates>
    <printtemplate v-if="tab=='current'" :template="loadedTemplate" @save="onEdit"></printtemplate>

  </div>
</template>

<script>
import printtemplates from '@/components/manufacturing.settings/boxes.print'
import printtemplate from '@/components/manufacturing.settings/boxes.print.template'

export default {
  name: 'Print',
  components: {
    printtemplates,
    printtemplate,
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Manufacturing',
          to: '/settings/manufacturing'
        },
        {
          text: 'Boxes',
          to: '/settings/manufacturing/boxes'
        },
        {
          text: 'Print',
          active: true
        }
      ],
      loadedTemplate: null
    }
  },
  watch: {
    tab() {
      if(this.tab != 'current') {
        this.loadedTemplate = null
      }
    }
  },
  methods: {
    onCreate() {
      this.loadedTemplate = { 
        id: null, 
        name: 'New',
        pagewidth: 200, 
        pageheight: 180,
        pagemargintop: 0,
        pagemarginbottom: 0,
        pagemarginleft: 0,
        pagemarginright: 0,
        barcodetype: 'datamatrix',
        barcodewidth: 150,
        barcodeheight: 150,
        fontsize: 10  
      }
      this.tab = 'current'
    },
    onEdit(item) {
      this.loadedTemplate = item
      this.tab = 'current'
    },
  },
  async mounted() {
    this.tab = 'templates'
  }
}
</script>
