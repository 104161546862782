<template>
<div>

  <b-form v-on:submit.prevent="onSave">
  <b-input-group prepend="Name" class="mt-3">
    <b-form-input ref="name" type="text" v-model="name" :disabled="id != null"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Width" class="mt-4">
    <b-form-input ref="width" type="number" v-model="pagewidth"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Height" class="mt-3">
    <b-form-input type="number" v-model="pageheight"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Margin Top" class="mt-4">
    <b-form-input type="number" v-model="pagemargintop"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Margin Bottom" class="mt-3">
    <b-form-input type="number" v-model="pagemarginbottom"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Margin Left" class="mt-3">
    <b-form-input type="number" v-model="pagemarginleft"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Margin Right" class="mt-3">
    <b-form-input type="number" v-model="pagemarginright"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Barcode Type" class="mt-4">
    <b-form-select v-model="barcodetype" :options="barcodetypes"></b-form-select>
  </b-input-group>
  <b-input-group prepend="Barcode Width" class="mt-3">
    <b-form-input type="number" v-model="barcodewidth"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Barcode Height" class="mt-3">
    <b-form-input type="number" v-model="barcodeheight"></b-form-input>
  </b-input-group>
  <b-input-group prepend="Font Size" class="mt-4">
    <b-form-input type="number" v-model="fontsize"></b-form-input>
  </b-input-group>

  <b-button-group>
    <b-button ref="submit" class="mt-4" type="submit" variant="outline-primary"><b-icon-hdd></b-icon-hdd> Save</b-button>
    <b-button class="mt-4" variant="outline-primary" v-on:click.prevent="onTest"><b-icon-printer></b-icon-printer> Test</b-button>
  </b-button-group>

  </b-form>

</div>
</template>

<script>
import { printService } from '@/services';
import printJS from 'print-js'

export default {
  name: 'PrintTemplate',
  props: [
    'template'
  ],
  data() {
    return {
      id: null, 
      name: 'New',
      pagewidth: 200, 
      pageheight: 180,
      pagemargintop: 0,
      pagemarginbottom: 0,
      pagemarginleft: 0,
      pagemarginright: 0,
      barcodetype: 'datamatrix',
      barcodewidth: 150,
      barcodeheight: 150,
      fontsize: 10,
      barcodetypes: [
        { value: 'datamatrix', text: 'Datamatrix' },
        { value: 'qr', text: 'QR Code' },
        { value: 'pdf417', text: 'PDF 417' },
        { value: 'code39', text: 'Code 39' },
        { value: 'code128', text: 'Code 128' },
      ],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.id = this.template.id
      this.name = this.template.name
      this.pagewidth = this.template.pagewidth,
      this.pageheight = this.template.pageheight
      this.pagemargintop = this.template.pagemargintop
      this.pagemarginbottom = this.template.pagemarginbottom
      this.pagemarginleft = this.template.pagemarginleft
      this.pagemarginright = this.template.pagemarginright
      this.barcodetype = this.template.barcodetype
      this.barcodewidth = this.template.barcodewidth
      this.barcodeheight = this.template.barcodeheight
      this.fontsize = this.template.fontsize
    },
    onSave() {

      let template_config = {
        id: this.id,
        name: this.name,
        pagewidth: parseInt(this.pagewidth),
        pageheight: parseInt(this.pageheight),
        pagemargintop: parseInt(this.pagemargintop),
        pagemarginbottom: parseInt(this.pagemarginbottom),
        pagemarginleft: parseInt(this.pagemarginleft),
        pagemarginright: parseInt(this.pagemarginright),
        barcodetype: this.barcodetype,
        barcodewidth: parseInt(this.barcodewidth),
        barcodeheight: parseInt(this.barcodeheight),
        fontsize: parseInt(this.fontsize)
      }

      if(this.template.id == null) {

        // Create template
        printService.createTemplate(template_config)
          .then(response => {
            this.$emit('save', response)
            this.$bvToast.toast(`Saving template successful!`, {
              title: 'Success',
              autoHideDelay: 3000,
              variant: 'success'
            })
          })
          .catch(error => {
            this.$bvToast.toast(`${error}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })

      } else {

        // Update template
        printService.updateTemplate(template_config.id, template_config)
          .then(response => {
            this.$emit('save', response)
            this.$bvToast.toast(`Saving template successful!`, {
              title: 'Success',
              autoHideDelay: 3000,
              variant: 'success'
            })
          })
          .catch(error => {
            this.$bvToast.toast(`${error}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })

      }
    },
    onTest() {
      
      let settings = {
        printtype: 'pdf',
        pagetype: 'custom',
        pagewidth: parseInt(this.pagewidth),
        pageheight: parseInt(this.pageheight),
        pagemargintop: parseInt(this.pagemargintop),
        pagemarginbottom: parseInt(this.pagemarginbottom),
        pagemarginleft: parseInt(this.pagemarginleft),
        pagemarginright: parseInt(this.pagemarginright),
        barcodetype: this.barcodetype,
        barcodewidth: parseInt(this.barcodewidth),
        barcodeheight: parseInt(this.barcodeheight),
        fontsize: parseInt(this.fontsize)
      }

      printService.print(settings)
        .then(response => {
          let blob = new Blob([response], { type: 'application/pdf' })
          printJS({
            printable: window.URL.createObjectURL(blob), 
            type: 'pdf', 
            base64: false
          })
        })
        .catch(error => {
          this.$bvToast.toast(`${error}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
    }
  }
}
</script>